
* {
  font-family: $basic; }

.ma {
  margin-left: auto !important;
  margin-right: auto !important; }
.mla {
  margin-left: auto !important; }
.mra {
  margin-right: auto !important; }

.obfc {
  object-fit: cover; }

.w-100 {
  width: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  max-width: 100% !important; }
.w-90 {
  width: 90% !important;
  min-width: 90% !important;
  max-width: 90% !important; }
.w-80 {
  width: 80% !important;
  min-width: 80% !important;
  max-width: 80% !important; }
.w-70 {
  width: 70% !important;
  min-width: 70% !important;
  max-width: 70% !important; }
.w-60 {
  width: 60% !important;
  min-width: 60% !important;
  max-width: 60% !important; }
.w-50 {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important; }
.w-40 {
  width: 40% !important;
  min-width: 40% !important;
  max-width: 40% !important; }
.w-30 {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important; }
.w-20 {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important; }
.w-15 {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important; }
.w-10 {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important; }
.w-3 {
  width: 33.33333% !important;
  min-width: 33.33333% !important;
  max-width: 33.33333% !important; }
.w-4 {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important; }

.w-100,
.w-90,
.w-80,
.w-70,
.w-60,
.w-50,
.w-40,
.w-30,
.w-20,
.w-10,
.w-3,
.w-4 {
  @include v-sm {
    width: 96% !important;
    margin-left: auto;
    margin-right: auto; } }

.wide {
  @include v-sm {
    min-width: 90% !important;
    margin-left: auto;
    margin-right: auto; } }

.s-10,
.s-20,
.s-30,
.s-40,
.s-50,
.s-60,
.s-70,
.s-80,
.s-90,
.s-100 {
  width: 100% !important;
  clear: both;
  display: block;
  @include v-sm {
    height: 30px !important; } }

.s-0 {
  width: 100% !important;
  clear: both;
  display: block;
  overflow: hidden;
  height: 0 !important; }

.s-10 {
  height: 10px; }
.s-20 {
  height: 20px; }
.s-30 {
  height: 30px; }
.s-40 {
  height: 40px; }
.s-50 {
  height: 50px; }
.s-60 {
  height: 60px; }
.s-70 {
  height: 70px; }
.s-80 {
  height: 80px; }
.s-90 {
  height: 90px; }
.s-100 {
  height: 100px; }

.aic {
  align-items: center; }

.aifs {
  align-items: flex-start; }

.aife {
  align-items: flex-end; }

.jcc {
  justify-content: center; }

.jcfs {
  justify-content: flex-start; }

.jcfe {
  justify-content: flex-end; }

.jcsa {
  justify-content: space-around; }

.jcsb {
  justify-content: space-between; }

.center {
  justify-content: center; }

.text-left {
  margin: 0;
  text-align: left; }

.text-right {
  margin: 0;
  text-align: right; }

.rowrap {
  flex-flow: row wrap; }

.oh {
  overflow: hidden; }

.flex {
  display: flex; }

.mb-block {
  display: block; }

.btn {
  @include v-xs {
    font-size: 15px !important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important; } }

.h-100 {
  height: 100vh !important;
  @include v-sm {
    height: auto;
    padding-top: 20%; } }

.h-90 {
  height: 90vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-80 {
  height: 80vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-70 {
  height: 70vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-60 {
  height: 60vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-55 {
  height: 55vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-50 {
  height: 50vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-45 {
  height: 45vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-40 {
  height: 40vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-30 {
  height: 30vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-20 {
  height: 20vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.h-10 {
  height: 10vh;
  @include v-sm {
    height: auto;
    padding-top: 2%;
    padding-bottom: 2%; } }

.b-left {
  border-left: 1px solid #dedede; }

.b-right {
  border-right: 1px solid #dedede; }

.b-top {
  border-top: 1px solid #dedede; }

.b-bottom {
  border-bottom: 1px solid #dedede; }

.bg-grey {
  border-radius: 16px;
  background-color: rgba(217,217,217,.5); }

.bg-marine {
  background-color: $marine !important; }
.bg-theme {
  background-color: $theme !important; }
.bg-card {
  background-color: $card !important; }
.bg-blue {
  background-color: $blue !important; }
.bg-page {
  background-color: $blue !important; }

.c-red {
  color: $red !important; }
.c-page {
  color: $page !important; }
.c-lite {
  color: $lite !important; }
.c-grey {
  color: $grey !important; }
.c-pink {
  color: $pink !important; }
.c-dark {
  color: $dark !important; }
.c-blue {
  color: $blue !important; }
.c-card {
  color: $card !important; }
.c-theme {
  color: $theme !important; }
.c-marine {
  color: $marine !important; }
.c-white {
  color: $white !important; }
.c-black {
  color: $black !important; }

.medium {
  font-weight: 500 !important; }

.bold {
  font-weight: 700 !important; }

.upp {
  text-transform: uppercase; }
