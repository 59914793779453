/* Base */

html {
  font-family: $basic;
  font-weight: 300;
  font-size: 1rem;
  font-display: swap; }
body {
  width: 100%;
  font-size: 90%;
  font-family: $basic;
  font-weight: 500;
  font-smooth: always;
  overflow-x: hidden;
  -webkit-font-smoothing: antialised; }

.app {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between; }

.hero {
  display: flex;
  justify-content: center;
  height: 100vh;
  background: darken($card, 15%) url('../../assets/img/bg.webp') center top no-repeat;
  background-blend-mode: soft-light;
  background-attachment: fixed;
  @include v-sm {
    height: auto; } }

.site-title {
  @include v-xs {
    zoom: .85; } }

.navbar {
  background: darken($card, 15%) url('../../assets/img/bg.webp') center top no-repeat;
  background-blend-mode: soft-light; }

.loading {
  font-size: 1.5rem; }

#navbarNav {
  @include v-sm {
    position: fixed;
    display: block;
    padding: 24px;
    top: 90px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid $line;
    background-color: $back;
    transition: all ease-out .7s;
    line-height: 2;
    &.collapsed {
      visibility: visible;
      left: 0; }
    &.collapse {
      visibility: hidden;
      left: 120%; } } }

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
  transform: translate(4px,16px) !important; }

.cta-bot {
  background: darken($card, 15%) url('../../assets/img/bg-cta-bot.webp') center center no-repeat;
  background-blend-mode: soft-light;
  background-attachment: fixed;
  @include v-sm {
    height: auto !important;
    padding: 2vh 0 !important; } }

.cta-app,
.footer {
  background: conic-gradient(from 165.86deg at 47.62% -7.14%, #080B1A 0deg, #151941 360deg);
  * {
    @include v-sm {
      text-align: center !important; } }
  .nav-item {
    @include v-sm {
      margin-bottom: 20px;
      width: 100% !important;
      display: block !important; } } }

.cta-app {
  @include v-sm {
    height: auto !important;
    min-height: 130vh; } }

.country {
  width: 100%;
  height: 320px;
  object-fit: scale-down;
  border-radius: 4px; }

table {
  margin: 40px auto;
  background-color: lighten($light, 5%);
  td, th {
    font-size: 14px;
    padding: 5px 20px !important;
    border: 2px solid $white; } }

.page-pr {
  * {
    color: $card !important;
    font-size: 14px !important;
    line-height: 1.5; }
  a {
    color: $theme !important;
    text-decoration: none !important; }
  h3,
  h2 {
    font-size: 18px !important;
    margin: 20px auto;
    font-weight: 600; } }

ul {
  list-style-type: circle; }

.btn-primary {
  background-color: darken($theme, 15%);
  border-color: darken($theme, 15%); }

.btn-primary:hover {
  background-color: darken($theme, 20%);
  border-color: darken($theme, 20%); }

.navbar-brand {
  .logo {
    display: block;
    margin-left: -1px;
    margin-top: 30px;
    margin-bottom: -16px;
    font-size: 30px;
    font-weight: 600;
    line-height: 0;
    letter-spacing: .035em; }
  .slogan {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase; }
  img {
    margin-top: -8px; } }

.intro-subtitle {
  font-size: 18px;
  line-height: 1.65;
  @include v-sm {
    font-size: 18px; } }

.intro-title {
  font-size: 54px;
  font-weight: 800;
  @include v-sm {
    font-size: 32px; } }

.title {
  &-big {
    font-size: 40px;
    font-weight: 500;
    @include v-xs {
      font-size: 24px;
      width: 100% !important; } }
  &-medium {
    font-size: 20px;
    font-weight: 500;
    @include v-xs {
      font-size: 16px;
      width: 100% !important; } }
  &-small {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    @include v-xs {
      font-size: 16px;
      width: 100% !important; } }
  &-mini {
    font-size: 14px;
    font-weight: 500; } }

.w-60 {
  width: 60%;
  @include v-sm {
    min-width: 90% !important;
    margin-left: auto;
    margin-right: auto; } }

.ma {
  margin-left: auto;
  margin-right: auto; }
