@mixin v-xl {
  @media only screen and (min-width: 1441px) {
    @content; } }
@mixin v-lg {
  @media only screen and (max-width: 1440px) {
    @content; } }
@mixin v-md {
  @media only screen and (max-width: 1024px) {
    @content; } }
@mixin v-sm {
  @media only screen and (max-width: 768px) {
    @content; } }
@mixin v-xs {
  @media only screen and (max-width: 480px) {
    @content; } }
