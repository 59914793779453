.page-title {
  margin-bottom: 20px;
  height: 300px;
  padding-top: 100px;
  background: #ccc url('../../assets/img/bg-cta-bot.webp') center center no-repeat;
  background-blend-mode: overlay;
  background-attachment: fixed;
  box-shadow: inset 0 10px 24px rgba(255,255,255,.75); }

.title-page {
  font-size: 40px;
  font-weight: 800;
  @include v-sm {
    font-size: 24px; } }

.top-10 {
  @include v-sm {
    margin-top: 16px; } }
