$red: #FA7375;
$light: #EDEDED;
$lite: #d9d9d9;
$grey: #B9B9B9;
$pink: #FE3E87;
$dark: #282828;
$page: #515151;
$card: #3e4258;
$theme: #3da8ef;
$blue: lighten($theme, 30%);
$marine: #13DBE8;
$white: #fff;
$black: #000;
$back: #10264A;
$line: #0a1c39;

$basic: 'Manrope', sans-serif;

.au {
  animation: Up 1.7s .4s; }
.ad {
  animation: Down 1.7s .4s; }
.al {
  animation: Left 1.7s .4s; }
.ar {
  animation: Right 1.7s .4s; }

@keyframes Right {
  from {
    transform: translateX(60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Left {
  from {
    transform: translateX(-60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Up {
  from {
    transform: translateY(60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Down {
  from {
    transform: translateY(-60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }
